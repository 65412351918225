import { createStore } from "vuex";

export default createStore({
  state: {
    user: JSON.parse(window.localStorage.getItem("user_data")) || null,
    isLoggedIn: !!window.localStorage.getItem("user_access_token"),
    selectedConcessionaire: JSON.parse(window.localStorage.getItem("selected_concessionaire")) || null,
  },
  mutations: {
    setUser(state, userData) {
      state.user = userData;
      state.isLoggedIn = true;
      window.localStorage.setItem("user_data", JSON.stringify(userData));
      window.localStorage.setItem("user_access_token", userData.token);
    },
    clearUser(state) {
      state.user = null;
      state.isLoggedIn = false;
      state.selectedConcessionaire = null;
      window.localStorage.removeItem("user_data");
      window.localStorage.removeItem("user_access_token");
      window.localStorage.removeItem("selected_concessionaire");
    },
    setSelectedConcessionaire(state, concessionaire) {
      state.selectedConcessionaire = concessionaire;
      window.localStorage.setItem('selected_concessionaire', JSON.stringify(concessionaire));
    },
  },
  actions: {
    login({ commit }, userData) {
      commit("setUser", userData);
    },
    logout({ commit }) {
      commit("clearUser");

      const backdrops = document.querySelectorAll(".modal-backdrop");
      backdrops.forEach((backdrop) => backdrop.remove());
    },
  },
  getters: {
    isAuthenticated(state) {
      return state.isLoggedIn;
    },
    getUser(state) {
      return state.user;
    },
    getToken(state) {
      return state.user?.token || null;
    },
  },
});
