<template>
  <div
    class="modal fade"
    id="recoverPasswordModal"
    tabindex="-1"
    role="dialog"
    aria-labelledby="recoverPasswordModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-body">
          <h2>RECUPERAR CONTRASEÑA</h2>

          <div
            v-if="successMessage"
            class="alert alert-success"
            style="margin-bottom: 15px; text-align: center"
          >
            {{ successMessage }}
          </div>

          <div
            v-if="errorMessage"
            class="alert alert-danger"
            style="margin-bottom: 15px; text-align: center"
          >
            {{ errorMessage }}
          </div>

          <input
            type="text"
            placeholder="Ingrese su DNI"
            v-model="dni"
            class="form-control"
          />

          <button
            type="button"
            class="btn-ingresar btn-enviar"
            @click="handleRecoverPassword"
            :disabled="loading"
          >
            <span v-if="loading">
              <i class="fa fa-spinner fa-spin"></i> Enviando...
            </span>
            <span v-else>ENVIAR</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import { sendResetLinkEmail } from "@/services/api/authService";
import * as bootstrap from "bootstrap";
import Swal from "sweetalert2";

export default {
  name: "RecoverPasswordModalComponent",
  setup() {
    const dni = ref("");
    const loading = ref(false);
    const errorMessage = ref("");

    const handleRecoverPassword = async () => {
      if (!dni.value) {
        errorMessage.value = "Por favor, ingrese su DNI.";
        return;
      }

      errorMessage.value = "";
      loading.value = true;

      try {
        await sendResetLinkEmail(dni.value);

        Swal.fire({
          icon: "success",
          title: "Recuperación enviada",
          text: "El enlace de recuperación se ha enviado a su correo.",
          confirmButtonText: "Aceptar",
        });

        const modalElement = document.getElementById("recoverPasswordModal");
        const modalInstance = bootstrap.Modal.getInstance(modalElement);
        if (modalInstance) {
          modalInstance.hide();
        }
      } catch (error) {
        errorMessage.value = error || "Error al procesar la solicitud.";
      } finally {
        loading.value = false;
      }
    };

    return {
      dni,
      loading,
      errorMessage,
      handleRecoverPassword,
    };
  },
};
</script>


<style scoped>
.modal-content {
  background: #f7f9fc;
  padding: 40px;
  border-radius: 10px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}

.modal-body {
  text-align: center;
}

.modal-content h2 {
  color: #02225f;
  margin-bottom: 20px;
  font-size: 2.2rem;
}

.modal-content input {
  border: 1px solid #ccc;
  border-radius: 5px;
  margin: 0 auto 20px;
  width: 80%;
  padding: 10px;
  font-size: 16px;
}

.modal-content .btn-enviar {
  background: #02225f;
  color: #fff;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  font-size: 16px;
  transition: background 0.3s;
}

.modal-content .btn-enviar:hover {
  background: #001840;
}

.alert {
  font-size: 14px;
}
</style>
