<template>
  <section class="welcome-container">
    <div class="container">
      <div class="row align-items-center special-container">
        <div class="col-12 col-md-4 text-center logo-container">
          <img :src="logo" alt="Logo" class="logo" />
        </div>
        <div class="col-12 col-md-8 info-container">
          <div class="row">
            <div class="col-12">
              <p class="company-name text-center">
                {{ userName }} - {{ concessionaireName }}
              </p>
            </div>
            <div class="col-12 col-md-6">
              <p>CUIT: <strong>{{ cuit }}</strong></p>
              <p>{{ type }}</p>
              <p>Número de Socio: <strong>{{ memberNumber }}</strong></p>
            </div>
            <div class="col-12 col-md-6">
              <p>{{ streetName }}</p>
              <p>{{ localityAndProvince }}</p>
              <p>Teléfono: <strong>{{ phone }}</strong></p>
              <p>
                Email:
                <a :href="`mailto:${email}`">
                  {{ email }}
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="row text-center mt-4">
        <div class="col-12">
          <h2 class="welcome-title">BIENVENIDO A ACARA</h2>
          <p class="welcome-text">
            Acá vas a poder encontrar información actualizada y completa para
            llevar adelante un mejor desarrollo de tu negocio.
          </p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { computed } from "vue";
import { useStore } from "vuex";

export default {
  name: "WelcomeComponent",
  setup() {
    const store = useStore();

    const selectedConcessionaire = computed(() => store.state.selectedConcessionaire);

    const userName = computed(() => store.state.user?.name || "");
    
    const concessionaireName = computed(() => selectedConcessionaire.value?.business_name || "");
    const cuit = computed(() => selectedConcessionaire.value?.cuit || "");
    const type = computed(() => (selectedConcessionaire.value?.type === 1 ? "Casa Central" : "Sucursal"));
    const memberNumber = computed(() => selectedConcessionaire.value?.member_number || "");
    const streetName = computed(() => selectedConcessionaire.value?.street_name || "");
    const localityAndProvince = computed(
      () => `${selectedConcessionaire.value?.locality_name || ""}, ${selectedConcessionaire.value?.province_name || ""}`
    );
    const phone = computed(() => selectedConcessionaire.value?.phone || "");
    const email = computed(() => selectedConcessionaire.value?.email || "");
    const logo = computed(
      () => selectedConcessionaire.value?.brand_logo || require("@/assets/images/logo.png")
    );

    return {
      userName,
      concessionaireName,
      cuit,
      type,
      memberNumber,
      streetName,
      localityAndProvince,
      phone,
      email,
      logo,
    };
  },
};
</script>

<style scoped>
.welcome-container {
  background-color: #b3d8e6;
  padding-top: 153px;
  padding-bottom: 50px;
  position: relative;
  z-index: 2;
  min-height: auto;
}

.special-container {
  position: absolute;
  top: 6%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 65%;
  margin-bottom: 50px;
  z-index: 3;
  display: flex;
  justify-content: center;
  align-items: stretch!important;
  text-align: center;
  min-height: auto;
}

.logo-container {
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px;
}

.logo {
  max-width: 100%;
  height: auto;
}

.info-container {
  background-color: #054e8f;
  color: #fff;
  padding: 10px;
}

.company-name {
  font-size: 1.4rem;
  font-weight: bold;
  margin-bottom: 15px;
  text-transform: uppercase;
}

a {
  color: #ffcc00;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

.welcome-title {
  font-size: 1.8rem;
  font-weight: bold;
  color: #054e8f;
  margin-bottom: 10px;
}

.welcome-text {
  font-size: 1rem;
  color: #054e8f;
}

@media (max-width: 768px) {
  .welcome-container {
    padding-top: 573px; 
    padding-bottom: 141px; 
  }

  .special-container {
    flex-direction: column;
        top: 26%;
    left: 52%;
    width: 84%;
  }

  .welcome-below {
    order: 2; 
  }

  .info-container {
    padding: 15px; 
  }

  .company-name {
    font-size: 1.2rem; 
  }

  .welcome-title {
    font-size: 1.5rem; 
  }

  .welcome-text {
    font-size: 0.9rem; 
  }
}
</style>
