<template>
  <div class="sobre-nosotros">
    <div v-if="loading" class="loading-indicator">
      <LoadingIndicator />
    </div>
    <div v-else>
      <div class="carousel-container">
        <CarouselComponent :slides="allViews.sliders" />
        <div v-if="allViews.sliders.length > 0"></div>
      </div>

      <BlockComponent
        v-for="block in allViews.blocks"
        :key="block.id"
        :block="block"
        first-section-class="block-container"
      />
      <ServicesComponent
        v-if="allViews.cards.length > 0"
        :services="allViews.cards"
      />
      <ButtonLinkComponent
        v-if="allViews.buttonLinks.length > 0"
        :buttonLinks="allViews.buttonLinks"
      />
      <NovedadComponent
        v-if="allViews.articles.length > 0"
        :articles="allViews.articles"
      />

            <div class="aliquot-section">
        <AliquotComponent v-if="isAuthenticated" />
      </div>

      <CalendarComponent
        :events="localEvents"
        :enabledDates="enabledDates"
        @fetch-date-events="handleFetchDateEvents"
        @month-changed="handleMonthChange"
      />


    </div>
  </div>
</template>

<script setup>
import { onMounted, computed } from "vue";
import { useImageLoader } from "@/composables/useImageLoader";
import { fetchViewByUrl } from "@/services/api/view.js";
import { useCalendar } from "@/composables/useCalendar";

import CarouselComponent from "@/components/carousel/CarouselComponent.vue";
import BlockComponent from "@/components/block/BlockComponent.vue";
import ButtonLinkComponent from "@/components/bottonLink/ButtonLinkComponent.vue";
import LoadingIndicator from "@/components/common/LoadingIndicator.vue";
import ServicesComponent from "@/components/servicios/Servicio.vue";
import NovedadComponent from "@/components/novedades/Novedad.vue";
import CalendarComponent from "@/components/calendar/CalendarComponent.vue";
import AliquotComponent from "@/components/aliquot/AliquotComponent.vue";
import { useStore } from "vuex";

const { loading, allViews, loadImages } = useImageLoader();
const store = useStore();

const isAuthenticated = computed(() => !!store.state.user);

const {
  localEvents,
  enabledDates,
  loadCalendarData,
  handleFetchDateEvents,
  handleMonthChange,
} = useCalendar();

onMounted(async () => {
  loadImages(fetchViewByUrl);
  const currentMonth = new Date().toISOString().slice(0, 7) + "-01";
  await loadCalendarData(currentMonth);
});
</script>

<style scoped>
.sobre-nosotros {
  margin: 0 auto;
  position: relative;
}

.carousel-container {
  position: relative;
}

.aliquot-section {
  margin-top: 2rem;
  text-align: center;
}
</style>
