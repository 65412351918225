<template>
  <section class="accesos-directos" style="background-color: #efeff6">
    <div class="container">
      <div class="row">
        <div class="col-12 text-center">
          <h3>ACCESOS DIRECTOS</h3>
        </div>
      </div>
      <div class="row mt-5">
        <div
          class="col-12 col-md-3 text-center"
          v-for="access in accesses"
          :key="access.id"
        >
          <h5>{{ access.title }}</h5>
          <i :class="access.icon" style="font-size: 58px; color: #00a0e1"></i>
          <br /><br />
          <button class="btn btn-primary" @click="handleAccessClick(access)">
            {{ access.buttonText }}
          </button>
        </div>
      </div>
    </div>
  </section>
</template>


<script>
import { ref, computed } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { useSweetAlert } from "@/composables/useSweetAlert";

export default {
  name: "AccesosDirectosComponent",
  setup() {
    const { showDetailsModal } = useSweetAlert();
    const router = useRouter();
    const store = useStore();

    const user = computed(() => store.state.user);

    const selectedConcessionaire = computed(
      () => store.state.selectedConcessionaire
    );

    const accesses = ref([
      {
        id: 1,
        title: "Circulares",
        icon: "far fa-file-pdf",
        type: "link",
        link: "/socios-circulares",
        buttonText: "Ingresar",
      },
      {
        id: 2,
        title: "Regional",
        icon: "fas fa-globe-americas",
        type: "modal",
        modalTarget: "#regionalModal",
        buttonText: "Ingresar",
      },
      {
        id: 3,
        title: "Estadísticas",
        icon: "fas fa-chart-line",
        type: "link",
        link: "/socios-reportes",
        buttonText: "Ingresar",
      },
      {
        id: 4,
        title: "Consultas",
        icon: "fas fa-phone-alt",
        type: "link",
        link: "/contacto",
        buttonText: "Ingresar",
      },
    ]);

    const handleAccessClick = (access) => {
      if (access.type === "link") {
        if (access.link === "/contacto") {
          router.push({ path: access.link, query: { title: "CONTACTO SOCIOS" } });
        } else {
          router.push({ path: access.link });
        }
        return;
      }

      if (access.id === 1) {
        const title = "Informacion de tu Administrador";
        const body = user.value?.owner?.name
          ? user.value.owner.name
          : "El concesionario no posee administrador. Ante cualquier consulta podés escribir un email a socios@acara.org.ar";

        const additionalDetails = [
          {
            label: "Email",
            value: user.value?.owner?.email || "Email no disponible",
          },
          {
            label: "Celular",
            value:
              user.value?.owner?.business_phone || "Teléfono no disponible",
          },
        ];

        showDetailsModal(title, body, additionalDetails);
      }

      if (access.id === 2) {
        const title = "Informacion del Regional";
        const body = selectedConcessionaire.value?.regional_name
          ? `Regional: ${selectedConcessionaire.value.regional_name}`
          : "Información regional no disponible";

        const additionalDetails = [
          {
            label: "Email",
            value: selectedConcessionaire.value?.email || "Email no disponible",
          },
          {
            label: "Celular",
            value:
              selectedConcessionaire.value?.business_phone ||
              "Teléfono no disponible",
          },
        ];

        showDetailsModal(title, body, additionalDetails);
      }
    };

    const handleRedirect = (path) => {
      router.push({ path });
    };

    return {
      accesses,
      handleAccessClick,
      handleRedirect,
    };
  },
};
</script>

<style scoped>
.accesos-directos {
  background-color: #efeff6;
  padding: 25px 0;
}

.accesos-directos h3 {
  text-transform: uppercase;
  color: #00a0e1;
  font-weight: 400;
}

.accesos-directos h5 {
  text-transform: uppercase;
  color: #00a0e1;
  font-weight: 400;
  font-size: 1.2rem;
  padding: 12px 0;
}

svg {
  font-size: 58px;
  color: #00a0e1;
}

a {
  color: #007bff;
  text-decoration: none;
  background-color: transparent;
}

.btn {
  background-color: #00999c;
  border-color: #00999c;
  color: #ffffff;
  text-transform: uppercase;
  font-size: 14px;
  border-radius: 0;
  padding: 5px 35px;
}
</style>
