<template>
  <div class="ingresar">
    <a
      href="#"
      data-bs-toggle="modal"
      data-bs-target="#ingresarModal"
      :class="{'nav-link': isMobile}"
    >
      <div class="link" v-if="!isMobile">INGRESO SOCIOS</div>
      <div class="flecha" v-if="!isMobile"></div>
      <span v-if="isMobile">{{ name }}</span>
    </a>
    <LoginModalComponent />
    <RecoverPasswordModalComponent />
  </div>
</template>

<script>
import { ref, onMounted, onBeforeUnmount } from "vue";
import LoginModalComponent from "@/components/header/login/LoginModalComponent.vue";
import RecoverPasswordModalComponent from "@/components/header/login/RecoverPasswordModalComponent.vue";

export default {
  name: "LoginButtonComponent",
  components: {
    LoginModalComponent,
    RecoverPasswordModalComponent,
  },
  props: {
    name: {
      type: String,
      default: "INGRESO SOCIOS",
    },
  },
  setup() {
    const isMobile = ref(false);

    const checkIsMobile = () => {
      isMobile.value = window.innerWidth <= 768;
    };

    onMounted(() => {
      checkIsMobile();
      window.addEventListener("resize", checkIsMobile);
    });

    onBeforeUnmount(() => {
      window.removeEventListener("resize", checkIsMobile);
    });

    return {
      isMobile,
    };
  },
};
</script>


<style scoped>
.ingresar {
  display: flex;
  align-items: center;
  justify-content: center;
}

.ingresar a {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: #2CBAD2;
  font-weight: 600;
  padding: 0;
}

.link {
  color: #fff;
  background: #BFBFBF;
  padding: 5px 20px;
  border-radius: 5px 0 0 5px;
  font-size: 16px;
  margin: 0;
  display: inline-block;
}

.flecha {
  content: '';
  width: 0;
  height: 0;
  border-top: 17px solid transparent;
  border-bottom: 16px solid transparent;
  border-left: 16px solid #BFBFBF;
  display: inline-block;
  margin: 0;
}

@media (max-width: 768px) {
  .link {
    padding: 6px 15px;
    font-size: 14px;
  }

  .flecha {
    border-left: 12px solid #BFBFBF;
  }

  .nav-link {
    color: #02225f !important;

    padding: 10px 15px;
    font-weight: 500;
    font-size: 14px;
    text-align: center;
    margin: 0;
    display: block;
  }

  .nav-link:hover,
  .nav-link:focus {
    background: #001f5b;
    color: #fff !important;
  }
}
</style>
