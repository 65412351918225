<template>
  <div v-if="isLoggedIn" class="user-menu">
    <select class="concessionaires-select" v-model="selectedConcessionaire">
      <option v-for="(concessionaire, index) in concessionaires" :key="index" :value="concessionaire.business_name">
        {{ concessionaire.business_name }}
      </option>
    </select>

    <span class="user-name">{{ userName }}</span>

    <span class="separator">|</span>

    <button class="logout-btn" @click="handleLogout">Salir</button>
  </div>
</template>

<script>
import { useStore } from "vuex";
import { computed, ref, watch } from "vue";
import { useRouter } from "vue-router";

export default {
  name: "UserMenuComponent",
  props: {
    userName: {
      type: String,
      required: true,
    },
    concessionaires: {
      type: Array,
      required: true,
    },
  },
  setup(props) {
    const store = useStore();
    const router = useRouter();

    const isLoggedIn = computed(() => store.getters.isAuthenticated);

    const selectedConcessionaire = ref(props.concessionaires[0]?.business_name || "");

    const handleLogout = () => {
      store.dispatch("logout");
      router.push({ name: "home" });
    };

    const updateSelectedConcessionaire = () => {
    const concessionaire = props.concessionaires.find(c => c.business_name === selectedConcessionaire.value);
    if (concessionaire) {
      store.commit('setSelectedConcessionaire', concessionaire);
    }
  };

    watch(selectedConcessionaire, updateSelectedConcessionaire);

    return {
      isLoggedIn,
      selectedConcessionaire,
      handleLogout,
    };
  },
};
</script>

<style scoped>
.user-menu {
  display: flex;
  align-items: center;
  gap: 10px;
}

.concessionaires-select {
  margin-right: 15px;
  text-align: center;
      width: 185px;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 5px 10px;
  font-size: 12px;
  font-family: Montserrat, sans-serif;
  color: #495057;
}

.user-name {
  font-weight: 800;
  color: #51b6b9;
}

.separator {
  color: #ccc;
  font-weight: 400;
}

.logout-btn {
  background: none;
  border: none;
  color: #51b6b9;
  font-weight: 800;
  font-size: 14px;
  cursor: pointer;
  text-decoration: none;
}

.logout-btn:hover {
  color: #ff151b;
}
</style>
