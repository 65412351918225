import { createRouter, createWebHistory } from "vue-router";
import HomeView from "@/views/HomeView.vue";
import SobreNosotros from "@/views/SobreNosotros.vue";
import DivisionesAutosView from "@/views/divisiones/DivisionesAutos.vue";
import DivisionesMotovehiculoView from "@/views/divisiones/DivisionesMotovehiculoView.vue";
import DivisionesMaquinariaAgricolaView from "@/views/divisiones/DivisionesMaquinariaAgricolaView.vue";
import SociosView from "@/views/servicios/SociosView.vue";
import AsesoriaLegal from "@/views/servicios/AsesoriaLegalView.vue";
import AsesoriaImpositiva from "@/views/servicios/AsesoriaImpositivaView.vue";
import AsesoriaRegistral from "@/views/servicios/AsesoriaRegistralView.vue";
import CapacitacionView from "@/views/servicios/CapacitacionView.vue";
import ContactoView from "@/views/contact/Contacto.vue";
import PreciosView from "@/views/PreciosView.vue"; 
import ComisionDirectivaView from "@/views/comision-directiva/ComisionDirectivaView.vue"; 
import CircularesView from "@/views/servicios/components/socios/CircularesComponent.vue";
import NovedadArticulo from "@/views/NovedadArticulo.vue";
import MultiStepForm from "@/views/members/MultiStepForm.vue";
import AdministradorUsuariosView from "@/views/administrador/AdministradorUsuariosView.vue"; 

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/sobre-nosotros",
    name: "sobre-nosotros",
    component: SobreNosotros,
  },
  {
    path: "/divisiones-autos",
    name: "divisiones-autos",
    component: DivisionesAutosView,
  },
  {
    path: "/divisiones-motovehiculos",
    name: "divisiones-motovehiculos",
    component: DivisionesMotovehiculoView,
  },
  {
    path: "/divisiones-maquinaria-agricola",
    name: "divisiones-maquinaria-agricola",
    component: DivisionesMaquinariaAgricolaView,
  },
  {
    path: "/socios",
    name: "socios",
    component: SociosView,
  },
  {
    path: "/asesoria-legal",
    name: "Asesoria legal",
    component: AsesoriaLegal,
  },
  {
    path: "/asesoria-impositiva",
    name: "Asesoria impositiva",
    component: AsesoriaImpositiva,
  },
  {
    path: "/asesoria-registral",
    name: "Asesoria registral",
    component: AsesoriaRegistral,
  },
  {
    path: "/capacitacion",
    name: "capacitacion",
    component: CapacitacionView,
  },
  {
    path: "/contacto",
    name: "contacto",
    component: ContactoView,
  },
  {
    path: "/guia-oficial-de-precios", 
    name: "precios",
    component: PreciosView,
  },
  {
    path: "/comision-directiva", 
    name: "comisionDirectiva",
    component: ComisionDirectivaView,
  },
  {
    path: "/socios-circulares", 
    name: "circulares",
    component: CircularesView,
  },
  {
    path: "/novedades/:slugTitle/:id",
    name: "novedades",
    component: NovedadArticulo,
    props: true, 
  },
  {
    path: "/socios-alta",
    name: "MultiStepForm",
    component: MultiStepForm,
  },
  {
    path: "/administrador/usuarios",
    name: "administrador-usuarios",
    component: AdministradorUsuariosView,
    meta: { requiresAuth: true }, 
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  const isAuthenticated = !!localStorage.getItem("user_access_token");
  to.meta.isAuthenticated = isAuthenticated;
  next();
});

router.beforeEach((to, from, next) => {
  const isAuthenticated = !!localStorage.getItem("user_access_token");

  if (to.meta.requiresAuth && !isAuthenticated) {
    next({ name: "home" });
  } else {
    next();
  }
});

export default router;
