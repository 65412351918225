import axios from "axios";
import store from "@/store";
import { setupResponseInterceptor } from "./authInterceptor";


const instance = axios.create({
  baseURL: process.env.VUE_APP_ACARA_WEB_BACKEND_URL
    ? process.env.VUE_APP_ACARA_WEB_BACKEND_URL
    : "http://localhost:8000/api/v1",
  withCredentials: false,
  headers: {
    "X-Requested-With": "XMLHttpRequest",
  },
});

instance.interceptors.request.use(
  (config) => {
    const token = store.state.isLoggedIn
      ? localStorage.getItem("user_access_token")
      : null;
      console.log("token",token)
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    } else {
      console.warn("No se encontró token de autenticación");
    }

    return config;
  },
  (error) => Promise.reject(error)
);

setupResponseInterceptor(instance);

export default instance;
