<template>
  <div class="usuarios-view">
    <header class="header">
      <h1>ADMINISTRADOR </h1>
    </header>
    <div class="table-container">
      <div class="table-header">
        <h2>Usuarios</h2>
      </div>
      <table class="table">
        <thead>
          <tr>
            <th>Nombre</th>
            <th>Razon social</th>
            <th>CUIT</th>
            <th>DNI</th>
            <th>Email</th>
            <th>Opciones</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="user in users" :key="user.id">
            <td>{{ user.name || "N/A" }}</td>
            <td>{{ user.business_phone || "N/A" }}</td>
            <td>{{ user.cuit || "N/A" }}</td>
            <td>{{ user.dni || "N/A" }}</td>
            <td>{{ user.email || "N/A" }}</td>
            <td class="options">
              <button
                @click="openViewsModal(user)"
                class="btn btn-sm btn-toggle-views"
              >
                <i class="fas fa-eye"></i>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
      <div v-if="users.length === 0" class="no-results">
        No se encontraron usuarios.
      </div>
    </div>

    <ViewsModal
      :isOpen="modalOpen"
      :user="selectedUser"
      @close="handleModalClose"
      @success="handleModalSuccess"
    />
  </div>
</template>

<script>
import { ref, onMounted } from "vue";
import { fetchUsers } from "@/services/api/user.js";
import ViewsModal from "@/components/administrador/ViewsModal.vue";
import Swal from "sweetalert2";

export default {
  name: "AdministradorUsuariosView",
  components: {
    ViewsModal,
  },
  setup() {
    const users = ref([]);
    const modalOpen = ref(false);
    const selectedUser = ref(null);

    const loadUsers = async () => {
      try {
        const response = await fetchUsers({ perPage: 10, userType: 2 });
        users.value = response.data;
      } catch (error) {
        console.error("Error al cargar usuarios:", error);
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Hubo un error al cargar los usuarios.",
        });
      }
    };

    const openViewsModal = (user) => {
      selectedUser.value = user;
      modalOpen.value = true;
    };

    const handleModalClose = () => {
      modalOpen.value = false;
    };

    const handleModalSuccess = () => {
      Swal.fire({
        icon: "success",
        title: "Éxito",
        text: "Las vistas han sido sincronizadas correctamente.",
      });
      modalOpen.value = false;
      loadUsers();
    };

    const editUser = (user) => {
      console.log("Editar usuario:", user);
    };

    const resetUser = async (userId) => {
      console.log("Reestablecer usuario con ID:", userId);
    };

    const deleteUser = async (userId) => {
      console.log("Eliminar usuario con ID:", userId);
    };

    onMounted(() => {
      loadUsers();
    });

    return {
      users,
      modalOpen,
      selectedUser,
      openViewsModal,
      handleModalClose,
      handleModalSuccess,
      editUser,
      resetUser,
      deleteUser,
    };
  },
};
</script>

<style scoped>
.usuarios-view {
  padding: 20px;
  font-family: "Montserrat", sans-serif;
}

.header {
  background-color: #001f5b;
  color: #ffffff;
  padding: 20px;
  text-align: center;
  border-radius: 5px;
  margin-bottom: 20px;
}

.header h1 {
  margin: 0;
  font-size: 24px;
  font-weight: bold;
}

.header h2 {
  margin: 5px 0 0 0;
  font-size: 16px;
  font-weight: normal;
}

.table-container {
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
  padding: 6rem;
}

.table-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.table-header h2 {
  font-size: 20px;
  font-weight: bold;
  color: #001f5b;
}

.nuevo-btn {
  background-color: #007bff;
  border: none;
  color: white;
  padding: 8px 15px;
  border-radius: 5px;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.nuevo-btn:hover {
  background-color: #0056b3;
}

.table {
  width: 100%;
  border-collapse: collapse;
}

.table th {
  background-color: #007bff;
  color: white;
  padding: 10px;
  text-align: left;
  font-size: 14px;
}

.table td {
  padding: 10px;
  border-bottom: 1px solid #ddd;
  font-size: 14px;
  justify-content: center;
  vertical-align: middle;
}

.options {
  display: flex;
  gap: 10px;
}

.options button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding: 5px;
  color: blue;
}

.options button .fas {
  font-size: 14px;
  color: #001f5b;
}

.options button.btn-edit .fas {
  color: #007bff;
}

.options button.btn-reset .fas {
  color: #ffc107;
}

.options button.btn-delete .fas {
  color: #dc3545;
}

.no-results {
  text-align: center;
  color: #666;
  font-size: 14px;
  margin-top: 20px;
}

.table th {
  background-color: #007bff;
  color: white;
  padding: 10px;
  text-align: center; /* Centramos el texto */
  font-size: 14px;
}

</style>
