import axios from "@/plugins/axios";

/**
 * Realiza la solicitud de login.
 *
 * @param {Object} credentials - Las credenciales del usuario.
 * @param {string} credentials.dni - El DNI del usuario.
 * @param {string} credentials.password - La contraseña del usuario.
 * @returns {Promise<Object>} Respuesta de la API con el token y el nombre.
 * @throws {Error} Si la solicitud falla.
 */
export async function login(credentials) {
  try {
    const response = await axios.post("/login-dni", credentials);

    if (response.data && response.data.data) {
      return response.data.data;
    }

    throw new Error("Error en la respuesta del servidor.");
  } catch (error) {
    throw error.response?.data?.message || "Error al iniciar sesión.";
  }
}

/**
 * Solicita el enlace de restablecimiento de contraseña.
 *
 * @param {string} dni - El DNI del usuario.
 * @returns {Promise<Object>} Respuesta de la API.
 * @throws {Error} Si la solicitud falla.
 */
export async function sendResetLinkEmail(dni) {
  try {
    const response = await axios.post("/password/email", { dni });
    return response.data;
  } catch (error) {
    throw error.response?.data?.message || "Error al solicitar el enlace de restablecimiento.";
  }
}

