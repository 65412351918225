<template>
  <swiper-container
    :slides-per-view="1"
    :navigation="true"
    :pagination="{ clickable: true }"
    :autoplay="{ delay: 3000, disableOnInteraction: false }"
    :loop="true"
    class="mySwiper"
  >
    <swiper-slide
      v-for="(slide, index) in filteredAndOrderedSlides"
      :key="index"
    >
      <div v-if="slide.image">
        <div v-if="isImage(slide.image)">
          <img
            :src="slide.image"
            :alt="slide.name"
            :class="['carousel-media', { 'carousel-top': positionTop }]"
            v-if="slide.imageLoaded"
          />
          <div v-else class="spinner-placeholder">Cargando...</div>
        </div>

        <div v-else-if="isVideo(slide.image)">
          <video loop autoplay muted class="d-block w-100 carousel-media">
            <source :src="slide.image" type="video/webm" />
            Tu navegador no soporta el formato de video.
          </video>
        </div>
      </div>
      <div class="carousel-caption d-none d-md-block">
        <h5 v-if="slide.title">{{ slide.title }}</h5>
        <p v-if="slide.content">{{ slide.content }}</p>
        <a
          :href="slide.link"
          class="btn btn-primary btn-style"
          v-if="slide.link"
        >
          {{ slide.buttonText || "Conocé más" }}
        </a>
      </div>
    </swiper-slide>
  </swiper-container>
</template>

<script>
import { computed, ref } from "vue";
import { getImageApi, getMediaPathApi } from "@/utils/mediaUtils.js";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

export default {
  name: "CarouselComponent",
  props: {
    slides: {
      type: Array,
      required: true,
      default: () => [],
    },
    positionTop: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const imageLoaded = ref(false);

    const isImage = (fileName) => {
      const imageExtensions = ["jpg", "jpeg", "png", "gif"];
      const extension = fileName.split(".").pop().toLowerCase();
      return imageExtensions.includes(extension);
    };

    const isVideo = (fileName) => {
      const videoExtensions = ["webm"];
      const extension = fileName.split(".").pop().toLowerCase();
      return videoExtensions.includes(extension);
    };

    const filteredAndOrderedSlides = computed(() => {
      if (Array.isArray(props.slides)) {
        return props.slides
          .filter((slide) => slide.status === 2)
          .sort((a, b) => a.order - b.order)
          .map(slide => {
            slide.imageLoaded = false;
            if (isImage(slide.image)) {
              const img = new Image();
              img.src = slide.image;
              img.onload = () => slide.imageLoaded = true;
            } else if (isVideo(slide.image)) {
              slide.imageLoaded = true;
            }
            return slide;
          });
      } else {
        return [];
      }
    });

    return {
      getImageApi,
      getMediaPathApi,
      isImage,
      isVideo,
      filteredAndOrderedSlides,
      imageLoaded,
    };
  },
};
</script>

<style scoped>
.carousel-caption {
  padding: 1rem;
  border-radius: 10px;
  position: absolute;
  bottom: 100px;
  left: 56%;
  transform: translateX(-50%);
  width: 80%;
  text-align: left;
}

.carousel-media {
  width: 100%;
  height: 325px;
  object-fit: cover;
}

.carousel-top {
  object-position: top;
}

.spinner-placeholder {
  width: 100%;
  height: 325px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #eaeaea;
}

@media (max-width: 768px) {
  .carousel-media, .spinner-placeholder {
    height: 250px;
  }
}

@media (max-width: 576px) {
  .carousel-media, .spinner-placeholder {
    height: 200px;
  }
}

.swiper-button-next,
.swiper-button-prev {
  color: #fff;
}

.mySwiper {
  width: 100%;
  height: auto;
  position: relative;
}

.btn {
  margin-top: 1rem;
  padding: 0.5rem 1rem;
  background-color: #007bff;
  border: none;
  border-radius: 5px;
  color: #fff;
  text-decoration: none;
  display: inline-block;
}

.btn:hover {
  background-color: #0056b3;
}
.btn-style {
  background: #5187b5;
  color: #ffffff;
}

.carousel-caption h5 {
  color: #ffffff;
  font-size: 65px;
  line-height: 65px;
  font-weight: 900;
}
.carousel-caption p {
  color: #ffffff;
  font-size: 1.1rem;
  font-weight: 400;
  text-transform: uppercase;
}
</style>
