import axios from "axios";

let isRefreshing = false;
let refreshSubscribers = [];

function onRefreshed(token) {
  refreshSubscribers.map((callback) => callback(token));
}

function addRefreshSubscriber(callback) {
  refreshSubscribers.push(callback);
}

export function setupResponseInterceptor(instance) {
  instance.interceptors.response.use(
    (response) => response,
    async (error) => {
      const originalRequest = error.config;

      if (error.response && error.response.status === 401 && !originalRequest._retry) {
        originalRequest._retry = true;

        if (isRefreshing) {
          return new Promise((resolve) => {
            addRefreshSubscriber((newToken) => {
              originalRequest.headers.Authorization = `Bearer ${newToken}`;
              resolve(instance(originalRequest));
            });
          });
        }

        isRefreshing = true;
        const refreshToken = localStorage.getItem("refresh_token");

        try {
          const response = await axios.post(
            `${process.env.VUE_APP_ACARA_WEB_BACKEND_URL}/refresh-token`,
            { refreshToken }
          );
          console.log(response)
          const newToken = response.data.accessToken;
          localStorage.setItem("user_access_token", newToken);
          instance.defaults.headers.Authorization = `Bearer ${newToken}`;

          isRefreshing = false;
          onRefreshed(newToken);

          return instance(originalRequest);
        } catch (refreshError) {
          localStorage.removeItem("user_access_token");
          localStorage.removeItem("refresh_token");
          window.location.href = "/login";
          return Promise.reject(refreshError);
        }
      }

      return Promise.reject(error);
    }
  );
}
