<template>
  <div
    class="modal fade"
    id="ingresarModal"
    tabindex="-1"
    role="dialog"
    aria-labelledby="ingresarModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-body">
          <div v-if="showLogin" class="ingreso-socios">
            <h2>INGRESO DE SOCIOS</h2>

            <div
              v-if="successMessage"
              class="alert alert-success"
              style="margin-bottom: 15px; text-align: center"
            >
              {{ successMessage }}
            </div>

            <div
              v-if="errorMessage"
              class="alert alert-danger"
              style="margin-bottom: 15px; text-align: center"
            >
              {{ errorMessage }}
            </div>

            <input
              type="text"
              placeholder="DNI"
              v-model="loginForm.dni"
              class="form-control"
            />

            <input
              type="password"
              placeholder="Contraseña"
              v-model="loginForm.password"
              class="form-control"
            />

            <button
              type="button"
              class="btn-ingresar btn-login"
              @click="handleLogin"
              :disabled="loading"
            >
              <span v-if="loading">
                <i class="fa fa-spinner fa-spin"></i> Iniciando sesión...
              </span>
              <span v-else>INGRESAR</span>
            </button>

            <button
              type="button"
              class="btn-ingresar btn-registrarse"
              @click="toggleLogin()"
            >
              REGISTRARSE
            </button>

            <a href="#" @click.prevent="openRecoverPasswordModal">
              ¿Olvidó su contraseña? Haga click aquí
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import { reactive, ref } from "vue";
import { useRouter } from "vue-router";
import * as bootstrap from "bootstrap";
import { login } from "@/services/api/authService";
import { useStore } from "vuex";

export default {
  name: "LoginModalComponent",
  setup() {
    const router = useRouter();
    const store = useStore();
    const showLogin = ref(true);
    const loading = ref(false);

    const loginForm = reactive({
      dni: "",
      password: "",
    });

    const errorMessage = ref("");
    const successMessage = ref("");

    const toggleLogin = () => {
      const modalElement = document.getElementById("ingresarModal");
      if (modalElement) {
        const modalInstance = bootstrap.Modal.getInstance(modalElement);
        if (modalInstance) {
          modalElement.addEventListener("hidden.bs.modal", () => {
            router.push({ path: "/socios-alta" });
          });
          modalInstance.hide();
        }
      } else {
        router.push({ path: "/socios-alta" });
      }
    };

    const openRecoverPasswordModal = () => {
      const modalElement = document.getElementById("recoverPasswordModal");
      const recoverModal = new bootstrap.Modal(modalElement);
      recoverModal.show();
    };

    const handleLogin = async () => {
      if (!loginForm.dni || !loginForm.password) {
        errorMessage.value = "Por favor, complete todos los campos.";
        return;
      }

      errorMessage.value = "";
      loading.value = true;

      try {
        const data = await login({
          dni: loginForm.dni,
          password: loginForm.password,
        });

        store.dispatch("login", data);

        if (data.concessionaires && data.concessionaires.length > 0) {
          const firstConcessionaire = data.concessionaires[0];
          store.commit("setSelectedConcessionaire", firstConcessionaire);
        }

        successMessage.value = "Inicio de sesión exitoso. Redirigiendo...";

        setTimeout(() => {
          const modalElement = document.getElementById("ingresarModal");
          if (modalElement) {
            const modalInstance = bootstrap.Modal.getInstance(modalElement);
            if (modalInstance) {
              modalInstance.hide();
            }
          }

          document.body.classList.remove("modal-open");
          document.body.style.overflow = "";
          document.body.style.paddingRight = "";

          const backdrops = document.querySelectorAll(".modal-backdrop");
          backdrops.forEach((backdrop) => backdrop.remove());

          router.push({ path: "/", name: "home" });
          loading.value = false;
        }, 2000);
      } catch (error) {
        errorMessage.value =
          error.response?.data?.message ||
          "Error al iniciar sesión. Inténtelo de nuevo.";
        loading.value = false;
      }
    };

    return {
      loading,
      showLogin,
      loginForm,
      errorMessage,
      toggleLogin,
      openRecoverPasswordModal,
      handleLogin,
    };
  },
};
</script>

<style scoped>
.modal-content {
  background: #2cbad2;
  padding: 50px;
}

.modal-body {
  text-align: center;
}

.modal-content h2 {
  color: #fff;
  text-align: center;
  margin-bottom: 30px;
  font-size: 2.4rem;
}

.mensaje-error,
.mensaje-error-recupero {
  color: #ff151b;
  margin-bottom: 10px;
  display: block;
}

.modal-content input {
  border-radius: 0;
  border: none;
  margin: 0 auto 20px auto;
  width: 60%;
}

.modal-content .btn-ingresar {
  background: #001f5b;
  color: #fff;
  border: none;
  margin: 20px auto;
  display: block;
  padding: 5px 30px;
  width: 220px;
}

.modal-content a {
  color: #fff !important;
  text-align: center;
  width: 90%;
  margin: 0 auto;
  display: block;
}

:global(.modal-backdrop.show) {
  z-index: 1 !important;
  opacity: 0 !important;
  position: unset !important;
}
</style>
