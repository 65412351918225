<template>
  <section class="accesos-directos">
    <div class="container">
      <div class="row">
        <div class="col-12 text-center">
          <h3>INFORMACIÓN RELEVANTE</h3>
        </div>
      </div>
      <div class="row mt-5 justify-content-around">
        <div
          class="col-12 col-md-4 text-center"
          v-for="access in accesses"
          :key="access.id"
        >
          <h5>{{ access.title }}</h5>
          <i :class="access.icon" style="font-size: 58px; color: #00a0e1"></i>
          <br /><br />
          <button
            v-if="access.type === 'modal'"
            class="btn btn-primary"
            @click="handleAccessClick(access)"
          >
            {{ access.buttonText }}
          </button>
          <a v-else :href="access.link">
            <button class="btn btn-primary">{{ access.buttonText }}</button>
          </a>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { ref, onMounted, computed } from "vue";
import { useStore } from "vuex";
import { useSweetAlert } from "@/composables/useSweetAlert";

export default {
  name: "AccesosDirectosComponent",
  setup() {
    const store = useStore();
    const { showDetailsModal } = useSweetAlert();

    const user = computed(() => store.state.user);

    const handleAccessClick = (access) => {
      if (access.id === 1) {
        const title = "Informacion de tu Administrador";
        const body = user.value?.owner?.name
          ? user.value.owner.name
          : "El concesionario no posee administrador. Ante cualquier consulta podés escribir un email a socios@acara.org.ar";

        const additionalDetails = [
          {
            label: "Email",
            value: user.value?.owner?.email || "Email no disponible",
          },
          {
            label: "Celular",
            value:
              user.value?.owner?.business_phone || "Teléfono no disponible",
          },
        ];

        showDetailsModal(title, body, additionalDetails);
      }
    };

    onMounted(() => {
      console.log("Usuario desde el store:", user.value);
    });

    const accesses = ref([
      {
        id: 1,
        title: "Tu Administrador",
        icon: "fas fa-globe-americas",
        type: "modal",
        modalTarget: "#administradorModal",
        buttonText: "Ver",
      },
/*       {
        id: 2,
        title: "Asamblea General Ordinaria",
        icon: "fas fa-person-booth",
        type: "link",
        link: "asamblea-general-ordinaria.php",
        buttonText: "Documentación Existente",
      },
      {
        id: 3,
        title: "Mis Certificados",
        icon: "fas fa-star",
        type: "link",
        link: "socios-certificados.php",
        buttonText: "Ingresar",
      }, */
    ]);

    return {
      accesses,
      user,
      handleAccessClick,
    };
  },
};
</script>

<style scoped>
.accesos-directos {
  background-color: #eeeceb;
  padding: 25px 0;
}

.accesos-directos h3 {
  text-transform: uppercase;
  color: #00a0e1;
  font-weight: 400;
}

.accesos-directos h5 {
  text-transform: uppercase;
  color: #00a0e1;
  font-weight: 400;
  font-size: 1.2rem;
  padding: 12px 0;
}

svg {
  font-size: 58px;
  color: #00a0e1;
}

.accesos-directos .btn {
  background-color: #00999c;
  border-color: #00999c;
  color: #ffffff;
  text-transform: uppercase;
  font-size: 14px;
  border-radius: 0;
  padding: 5px 35px;
}
</style>